@import "./variables";

body {
  *:focus {
    outline: none;
  }
  .position-relative {
    position: relative;
  }
  margin: 0;
  .auth-card {
    padding: 15px;
  }
  .form-container {
    margin: 15px;
  }
  .main-container {
    padding: 10px;
    margin-top: 70px;
  }
  .sidebar-back-icon {
    float: right;
    top: 8px;
    position: relative;
    cursor: pointer;
  }
  .text-right {
    text-align: right;
  }
  .form-input {
    margin: 10px 0;
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .drag-drop-container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3f51b5;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    p {
      color: #3f51b5;
      text-align: center;
      padding: 15px;
    }
    img {
      max-width: 100%;
    }
  }
  .drag-drop-wrapper{
    position: relative;
    .delete-container{
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 40px;
    }
    .MuiFormHelperText-root{
      margin-left: 14px;
      margin-right: 14px;
    }
  }
  .ce-block__content {
    font-size: 25px;
  }
  .error {
    border-color: #f44336;
  }
  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  .padding-left-10 {
    padding-left: 10px;
  }
  .padding-right-10 {
    padding-right: 10px;
  }
  .padding-top-10 {
    padding-top: 10px;
  }
  .padding-bottom-10 {
    padding-bottom: 10px;
  }

  .padding-left-20 {
    padding-left: 20px;
  }
  .padding-right-20 {
    padding-right: 20px;
  }
  .padding-top-20 {
    padding-top: 20px;
  }
  .padding-bottom-20 {
    padding-bottom: 20px;
  }
  .card-container {
    padding: 20px;
  }
  .bank-card-details {
    padding: 10px 25px;
    box-shadow: 1px 1px 4px 0px;
    margin: 15px 0;
  }
  .no-margin {
    margin: 0;
  }
  .available-balance {
    margin-top: 15px;
  }

  .dashboard-card {
    background-color: #fff;
    border: 0;
    border-radius: 8px;
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    box-shadow: 0 -3px 31px 0 rgba(36, 44, 54, 0.05),
      0 6px 20px 0 rgba(20, 25, 31, 0.06);
    .dashboard-title {
      .MuiCardHeader-title {
        font-size: 18px;
        cursor: pointer;
        color: #000;
        font-weight: 500;
      }
    }
    .card-count {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 0px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
    .number-text {
      margin-bottom: 0px;
      margin-top: 6px;
      font-size: 15px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      color: #292929;
    }
    .color-1 {
      color: #3699ff;
    }
    .color-2 {
      color: #1bc5bd;
    }
    .color-3 {
      color: #ffa800;
    }
  }

  .btn-primary {
    border-color: #0e3088;
    color: #fff;
    background: #0e3088;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    padding: 6px 20px;
    &:hover {
      color: #fff;
      background-color: #0069d9;
      border-color: #0062cc;
    }
  }

  .no-data {
    margin: 20px 0px;
    text-align: center;
    .no-data-icon {
      img {
        width: 65px;
        height: auto;
      }
    }
    .no-data-text {
      font-size: 14px;
      color: #797979;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
   
  }
  .partially-paid{
    .MuiChip-colorSecondary{
      background-color: #ffc107;
    }
  }
  .editor-block{
    min-height: 500px;
    border: 1px solid #3f51b5;
    border-radius: 4px;
    padding: 5px 20px;
    margin: 20px 20px 0px 0px;
    &.has-error{
      border-color: #f44336;
    }
  }
  @media (max-width: 576px){
    .editor-block{
      margin-right: 0px;
      min-height: 200px;
      padding: 0px 20px;
    }
  }
}
.w-full{
  width: 100%
}